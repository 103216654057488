
//VIEWS
//🟠🔴🔵🟢🟣🟡⚪⚫🟤
import Home from '/views👁️👁️👁️/⚪Home/home.js'
import About from '/views👁️👁️👁️/🟢About/about.js'
import Uikit from '/views👁️👁️👁️/⚙️Uikit/uikit.js'
import Error from '/views👁️👁️👁️/🚫Error/error.js'
import Legal from '/views👁️👁️👁️/📄Legal/legal.js'


import '/views👁️👁️👁️/⚪Home/styles.js'
import '/views👁️👁️👁️/🟢About/styles.js'
import'/views👁️👁️👁️/⚙️Uikit/styles.js'
import'/views👁️👁️👁️/🚫Error/styles.js'
import'/views👁️👁️👁️/📄Legal/styles.js'


// import Uikit from '/views👁️👁️👁️/Uikit⚙️'
// import Project from '/views👁️👁️👁️/Project🟢'
// import Contact from '/views👁️👁️👁️/Contact🔵'


// import Legal from '/views👁️👁️👁️/🔗Legal'

  




export function createViews(){


  this.pages = new Map()
    this.pages.set('home', new Home(this.main))
    this.pages.set('error', new Error(this.main))
    this.pages.set('uikit', new Uikit(this.main))
    this.pages.set('page', new Legal(this.main))
    this.pages.set('about', new About(this.main))



}

