
import Lenis from '@studio-freight/lenis'
//Basic
import Nav from '/components🦾🦾🦾/Nav🌤️'
import Loader from '/components🦾🦾🦾/Loader⏳'

//Mouse
// import Mouse from '/components🦾🦾🦾/Mouse🐭'


import {
  createViews


} from './👁️.js'

import {
  addPop,
  onPopState,
  onRequest,
  onChange,
  newView,
  resetLinks


} from './pop.js'

import {
  addEvents,
  // onTouchDown,
  // onTouchMove,
  // onTouchUp,
  // onKeyDown,
  // onWheel,
  // onScroll,
  // onResize


} from './events.js'


class App {
  constructor (info) {
    this.content = document.querySelector('#content')
    this.main = info[0]
    this.main.base = info[1].fields.base
    this.main.template = info[1].fields.template

    this.main.screen = {
      w:window.innerWidth,
      h:window.innerHeight
    }

    this.FR = 1e3 / 60

    this.speed = 0
    this.wheeling = 0
    this.isclick = 0
    this.searching = 0
    this.loaded = 0
    this.scry = 0

    this.resizevar = ''
    this.url = window.location.pathname


    this.initFuncs()
    this.initApp(info[1])
     
  }

  initFuncs(){

    this.update = (time) => {
      if(this.lenis){
        this.lenis.raf(time)
      }

      if (this.page) {
        this.page.update(this.speed)
      }
      
      // if (this.nav) {
      //   this.nav.update(time)
      // }

      if (this.mouse) {
        this.mouse.update()
      }
      if (this.gl) {
        this.gl.update(time,this.speed,this.lenis.scroll)
      }

      

      window.requestAnimationFrame(this.update)
    }


    //START RESIZE
    this.onResize = () => {
      this.main.design.L.total = ( ( this.main.design.L.w / window.innerWidth ) * 10 )
      this.main.design.L.total = 10 - ((10 - this.main.design.L.total) * this.main.design.L.multi)
      this.main.design.L.total = Math.min(10,this.main.design.L.total)

      //MULTI PARA EL WIDE
      // this.main.design.L.wide = ((window.innerHeight*10)/window.innerWidth).toFixed(2)
      // this.main.design.L.total *=  Math.min(1,(this.main.design.L.wide/this.main.design.L.ratio)*1.05)
      


      this.main.design.P.total = ( ( this.main.design.P.w / window.innerWidth ) * 10 )
      this.main.design.P.total = 10 - ((10 - this.main.design.P.total) * this.main.design.P.multi)
      this.main.design.P.total = Math.min(10,this.main.design.P.total)



      document.documentElement.style.setProperty("--ck_multiL", this.main.design.L.total)
      document.documentElement.style.setProperty("--ck_multiP", this.main.design.P.total)

      if (this.main.isTouch){
        document.documentElement.style.setProperty("--ck_hscr", window.screen.height+'px')
        document.documentElement.style.setProperty("--ck_hmin", document.documentElement.clientHeight+'px')
        // USAR ANIM
        // to(document.documentElement,{"--ck_hvar":window.innerHeight+"px",duration:.4})
        var isTouch = /Android|iPhone|iPad|iPod|Bla--ckBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
        if(!isTouch){
          location.reload()
        }
      
      }
      else{
        document.documentElement.style.setProperty("--ck_hscr", window.innerHeight+'px')
        document.documentElement.style.setProperty("--ck_hvar", window.innerHeight+'px')
        document.documentElement.style.setProperty("--ck_hmin", window.innerHeight+'px')
        var isTouch = /Android|iPhone|iPad|iPod|BlackBerry|IEMobile|Opera Mini/i.test(navigator.userAgent) || navigator.platform === 'MacIntel' && navigator.maxTouchPoints > 1
      
        if(isTouch){
          location.reload()
        }

      }

      this.main.screen.w = window.innerWidth
      this.main.screen.h = window.innerHeight

      if (this.canvas && this.canvas.onResize) {
        this.canvas.main.screen.w = window.innerWidth
        this.canvas.main.screen.h = window.innerHeight
        this.canvas.onResize()
      }
      if (this.page) {
        this.page.main.screen.w = window.innerWidth
        this.page.main.screen.h = window.innerHeight
        this.page.onResize()
      }

      if(this.mouse){
        this.mouse.main.screen.w = window.innerWidth
        this.mouse.main.screen.h = window.innerHeight

      }

      if(this.nav){
        this.nav.main.screen.w = window.innerWidth
        this.nav.main.screen.h = window.innerHeight
        this.nav.onResize()

      }
    }
    //END RESIZE

  }

  async initApp (temps) {
    //Events
    this.addEvents()
    //Pop
    this.addPop()



    //Lenis
    this.lenis = new Lenis({
      wheelEventsTarget:document.documentElement,
      lerp:.1,
      // duration:.6,
      smoothWheel:!this.main.isTouch,
      smoothTouch:false,
      normalizeWheel:true,
    })

    this.lenis.stop()

    if(this.main.isTouch == 0){
      this.createScrollBar()
    }


    this.createScrollCheck()
    //Loader
    let time = 10
    if(import.meta.env.DEV == true){
      time = 10
    }
    this.template = this.content.dataset.template
    
    this.loader = new Loader(this.main,temps.loader,this.main.device)
      
    await this.loader.create()
    
    this.loader.start()
    
    let firsttemp = undefined
    if(temps.main){
      firsttemp = temps.main
    }
    
    
    //PHIDE
    this.pHide = document.createElement('div')
    this.pHide.className = 'pHide'
    document.querySelector('body').appendChild(this.pHide)

    //Pages
    this.createViews()
    if(this.template.includes('lcl')){
      this.template = this.template.substring(0,this.template.length-3)
    }


    //WAITPAGE SOLO SI NO HAY CANVAS ( PORQUE CARGARÍA )
    //SI HAY CANVAS, SE HACE EL await this.page.create(this.content,this.main,firsttemp) Y EN EL PROMISE SE METE WAITCANVAS
    // ESTO ES POR SI EL CANVAS NECESITA COGER INFO DEL PAGE


    //Page
    this.page = this.pages.get(this.template)
     // await this.page.create(this.content,this.main,firsttemp)
    let waitpage = this.page.create(this.content,this.main,firsttemp)
    //Nav
    this.nav = new Nav(this.main)
    this.nav.create(temps.nav)



    
    //GL
    // this.gl = new gl(this.main)
    // await this.gl.create()
    // let waitcanvas = this.gl.createTemp(this.template)

  

    
    // this.video = new VideoModal(this.main)
    // this.video.on('start', ()=>this.controlScroll(0))
    // this.video.on('stop', ()=>this.controlScroll(1))

    //Mouse
    if(!this.main.isTouch && typeof Mouse==='function'){
      this.mouse = new Mouse(this.main)
     
    }

    
    //Lets play
    
    this.update()


	  await Promise.all([
      // loadpage,
      waitpage,
      // waitcanvas,
      this.timeout(time),
    
    ])

    

    this.loaded = 1
    this.firstView()
    


  }

  

  async firstView(){
    //Mouse
    if(this.mouse){
      this.mouse.start()
      this.mouse.reset()
    }
    
    await this.loader.hideIntro(this.template)

    this.page.show()
    if(this.gl){
      this.gl.show()
    }
    

    //State es para diferenciar entre el firstView y un PopState
    let state = this.page.start(0)

    this.nav.show()

    this.lenis.start()
    this.addControllers()

  }

  
  
  controlScroll(state){
    if(!this.page){
      return false
    }
    if(state==0){
      this.lenis.stop()
      this.page.stopScroll()
    }
    else{
      this.lenis.start()
      this.page.startScroll()

    }
  }


  timeout(ms){
    return new Promise(resolve => setTimeout(resolve, ms))
  }

  
  
  onPopState () {
    this.onChange({
      url: window.location.pathname,
      push: false
    })
  }

  // Controllers son modales, popstates y demás
  addControllers () {
    if(this.video){
      this.video.resetLinks()
    }
    this.resetLinks()

  }


  

  


  createScrollCheck(){
    if(this.main.isTouch == 0){
      this.scrollFn = ()=>{
        this.speed = this.lenis.velocity
        

        if(this.page){
  
          // this.page.scroll.target = this.lenis.targetScroll
          // this.page.scroll.current = this.lenis.animatedScroll
          // this.page.scroll.last = this.lenis.animatedScroll
          this.page.animIosScroll()
        }

        // this.scrollanim.progress(this.lenis.progress)


        if(Math.abs(this.speed) < 0.3){
          this.pHide.style.pointerEvents = 'none'
        }
        else{
  
          this.pHide.style.pointerEvents = 'all'
        }
        
  
  
        if(this.speed < 0){
          document.documentElement.classList.add('scroll-up')
        }
        else if(this.speed > 0){
          document.documentElement.classList.remove('scroll-up')
  
        }
  
        if(this.lenis.targetScroll == 0){
          document.documentElement.classList.remove('scroll-start')
        }
        else if(this.lenis.targetScroll > 0){
          document.documentElement.classList.add('scroll-start')
  
        }
        
  
        



      }
    }

    else{
      

      this.scrollFn = ()=>{
        this.speed = this.lenis.velocity
        if(Math.abs(this.speed) < 0.01){
          this.pHide.style.pointerEvents = 'none'
        }
        else{
  
          this.pHide.style.pointerEvents = 'all'
        }
        
        if(!this.page){
          return false
        }
  
        if(this.page.scroll.target > this.lenis.targetScroll){
          document.documentElement.classList.add('scroll-up')
        }
        else if(this.page.scroll.target < this.lenis.targetScroll){
          document.documentElement.classList.remove('scroll-up')
  
        }
  
        if(this.lenis.targetScroll == 0){
          document.documentElement.classList.remove('scroll-start')
        }
        else if(this.lenis.targetScroll > 0){
          document.documentElement.classList.add('scroll-start')
  
        }
        
  
        if(this.page){
  
          this.page.scroll.target = this.lenis.targetScroll
          
          this.page.animIosScroll()
        }
      }

    }


    this.lenis.on('scroll',this.scrollFn)
  }

  createScrollBar(){

    // this.scrBar = document.querySelector('.BGscroll')

    // this.scrollanim = new Interpol({
    //   duration:1000,
    //   paused:true,
    //   ease:'power3.easeInOut',
    //   props: {
    //     width: [0, 100,'%'],
    //   },
    //   onUpdate: ({ width }) => {
    //     if(this.scrBar){
    //       this.scrBar.style.width = width
    //     }
    //   },
    
    // })


  }
  
  getRnd(max){
    return Math.floor(Math.random() * max)
  }

  writeCt(el){
    let fakes = '##·$%&/=€|()@+09*+]}{['
    let fakeslength = fakes.length - 1

    new window.SplitType(el, { types: 'chars,words' })

    let splits = el.querySelectorAll('.char')
    for(let [i,a] of splits.entries()){


    a.innerHTML = '<span class="n">'+a.innerHTML+'</span>'
    
    
    let rnd = 0
    for ( let u=0;u<3;u++){
      rnd = this.getRnd(fakeslength)
      a.insertAdjacentHTML('afterbegin','<span class="f">'+fakes[rnd]+'</span>')

    }

    
    el.style.opacity = 0

    
  }

  }
  writeFn(parnt){
    
    
    let splits = parnt.querySelectorAll('.char')

    let anim = anime.timeline({
      autoplay: false
    })

    if(parnt.classList.contains('Awrite-inv')){
      anim
      .add({
        duration:1800,
        targets:parnt,
        opacity:1,
        autoplay: false,
        easing:(el,i,t)=>{ 
          return function(t){
            return window.Power4.inOut(t) 

          }
          return t
        
        }

      },0)
    }
    else{

      parnt.style.opacity = 1
    }

    for(let [i,a] of splits.entries()){
      let n = a.querySelector('.n')

      anim
      // .add({
      //   duration:11,
      //   targets:n,
      //   opacity:0,
      //   easing:'linear',
      //   autoplay: false,

      // },0)
      .add({
        duration:600,
        targets:n,
        opacity:1,
        autoplay: false,
        easing:(el,i,t)=>{ 
          return function(t){
            return window.Power4.inOut(t) 

          }
          return t
        
        }
          // easing:'linear'
      },(i*.1) * 1000)




      for(let [u,f] of a.querySelectorAll('.f').entries()){
        anim
        .add({
          duration:1,
          targets:f,
          opacity:0,
          easing:'linear',
          autoplay: false,

        },0)
        .add({
          duration:200,
          targets:f,
          opacity:[1,0],
          scaleX:[1,0],

          easing:(el,i,t)=>{ 
            return function(t){
              return window.Power4.inOut(t) 
  
            }
            return t
          
          }

        },((i*.1) + ((1+u)*.03)) * 1000) 
        
        
        


        // console.log((i*.1) * 100)
        // console.log(((i*.1) + ((1+u)*.03)) * 100)
      }
      
    }

    anim.play()
  }


}
//Start
App.prototype.createViews = createViews

//Events
App.prototype.addEvents = addEvents
// App.prototype.onTouchDown = onTouchDown
// App.prototype.onTouchMove = onTouchMove
// App.prototype.onTouchUp = onTouchUp
// App.prototype.onKeyDown = onKeyDown
// App.prototype.onWheel = onWheel
// App.prototype.onScroll = onScroll
// App.prototype.onResize = onResize

//Pop
App.prototype.addPop = addPop
App.prototype.onPopState = onPopState
App.prototype.onChange = onChange
App.prototype.onRequest = onRequest
App.prototype.newView = newView

App.prototype.resetLinks = resetLinks


//Rest



export default App