
//import SplitType from 'split-type'


export default class {
  constructor (obj,device,touch) {

    this.el = obj.el    
   

    this.pos = obj.pos
    this.device = device
    this.touch = touch
    
    this.DOM = {
      el:obj.el,
      line:obj.el.parentNode.querySelector('.linedraw'),
      linkbg:obj.el.parentNode.querySelector('.links_bg svg'),
      // tits:obj.el.parentNode.parentNode.querySelectorAll('.ttj')
     
    }


    this.active = 0
    this.isupdate = 1
    this.h = window.innerHeight
    
    this.object = {
      trazo:this.DOM.line.getTotalLength(),
      bg:this.DOM.linkbg.querySelector('path').getTotalLength()
    }
    
    this.animstick = {
      active:0,
      current:0,
      limit:0,
      start:0,
      prog:0,
      ratio:1
    }

    this.create()

    // this.navhead = document.querySelector('.nav_head')

  }
  
  async create(){


    // this.DOM.tits[0].classList.add('act')
    
    
    // this.anim.paused()
    this.onResize()
  }

  createAnim(){
    
  }

  killAnim(){
    // if(this.isstarted == 0 || !this.anim) return false
    // this.anim.kill()
  }

  

  check(entry,pos){
    let vis = false

    if(entry.isIntersecting == true){
      vis = true
      this.start()

    }
    else{
      
      this.stop()
    }

    return vis

  }
  
  start(){
    if(this.active==1){
      return false
    }
    this.active = 1
    this.animrep.play()
  }
  
  stop(){
    // console.log('stop intro'+this.active)

    if(this.active==0){
      return false
    }
    this.active = 0
    this.animrep.pause()
    this.killAnim()
    // this.anim.progress(0)
    // console.log('stop intro change'+this.active)

   

  }

  initEvents(){

  }
  removeEvents(){
   

  }
  touchScroll(){

  }
  update(pos){


    this.animstick.current = pos - this.animstick.start + this.h
    // CLAMP
    // this.animstick.current = clamp(0, this.animstick.limit, this.animstick.current)
    this.animstick.prog = (this.animstick.current  / this.animstick.limit).toFixed(4)
    
    this.anim.progress(this.animstick.prog)
   
  }

  onResize(pos){

    this.w = window.innerWidth
    this.h = window.innerHeight

    this.animstick.start = (this.DOM.el.getBoundingClientRect().y + window.scrollY).toFixed(0)
    this.animstick.limit = (this.DOM.el.clientHeight + this.h).toFixed(0)


    // let h = 0
    // for(let a of this.DOM.tits){
    //   if(h < a.clientHeight){
    //     h = a.clientHeight
    //   }


    // }
    // this.DOM.tits[0].parentNode.style.height = h+'px'

   
    this.update(window.scrollY)
  }
  
  
}
